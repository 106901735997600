<template>
  <div class="right-menu shipping-right">
    
    <div class="db-blue-section pt-0">

        <subHeader pageName="GALLERY"/>

        <tutorialsAction/>

        <div class="db-container">
            <div class="mianBlurBox pt-0">
                <div class="bannerUploadMainBlock">

                    <!-- loader block starts here -->
                    <div class="bannerUploadRow gallery" v-if="loadingItems">
                        <div class="bannerUploadItem gallery" v-for="i in 21" :key="`galleryLoader-`+i">
                            <div class="bannerSliderItem gallery">
                                <vue-skeleton-loader
                                    type="rect"
                                    :width="210"
                                    :height="210"
                                    animation="fade"
                                />
                            </div>
                        </div>  
                    </div>
                    <!-- loader block ends here -->

                    <!-- gallery items starts here -->
                    <div class="bannerUploadRow gallery" v-else>
                        
                        <div class="bannerUploadItem gallery" v-for="(gallery,index) in galleryList" :key="index" :class="isCheckedItem(gallery.id) ? 'checked' : ''">
                            
                            <div class="bannerSliderItem gallery" v-if="gallery.file_type != 'loader'">
                                <div class="bannerItemImg gallery video" v-if="gallery.file_type && gallery.file_type == 'video'">
                                    <img 
                                        src="/images/video_not_found.png" 
                                        v-if="isErrorVideo(gallery)"
                                    >
                                    <video 
                                        :src="gallery.file ? base_image_url + gallery.store_id + '/' + gallery.folder + '/' + gallery.file : ''"
                                        @error="handleMainVideoError($event,gallery)"
                                        v-else
                                    ></video>
                                    <div class="galleryItemOptions">
                                        <div class="galleryItemLeftOptions">
                                            <div class="ctmFeaturedCheckBox">
                                                <label class="ctmFeaturedContainer">
                                                    <input type="checkbox" v-model="checkedItems" :value="gallery.id"/>
                                                    <span class="checkmark"></span>
                                                </label>
                                            </div>
                                        </div>
                                        <div class="galleryItemRightOptions">
                                            <button @click.prevent="openVideoPreview(gallery)" class="zoomBtn">
                                                <el-tooltip class="box-item" effect="dark" content="Zoom" placement="top-start">
                                                    <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M12.9 14.32a8 8 0 1 1 1.41-1.41l5.35 5.33-1.42 1.42-5.33-5.34zM8 14A6 6 0 1 0 8 2a6 6 0 0 0 0 12zM7 7V5h2v2h2v2H9v2H7V9H5V7h2z" fill-rule="evenodd" fill="#1ec2c2"/>
                                                    </svg>
                                                </el-tooltip>
                                            </button>
                                            <button @click.prevent="handleDelete(gallery)">
                                                <el-tooltip class="box-item" effect="dark" content="Remove" placement="top-start">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15">
                                                        <path id="close" d="M24,10.313,22.687,9,16.5,15.187,10.313,9,9,10.313,15.187,16.5,9,22.687,10.313,24,16.5,17.813,22.687,24,24,22.687,17.813,16.5Z" transform="translate(-9 -9)" fill="#1ec2c2"/>
                                                    </svg>
                                                </el-tooltip>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="bannerItemImg gallery" v-else>
                                    <img 
                                        :src="gallery.file ? base_image_url + gallery.store_id + '/' + gallery.folder + '/' + gallery.file : '/images/WebLogo@2x.jpg'" 
                                        alt=""
                                        @error="handleImageError"
                                    >
                                    <div class="galleryItemOptions" >
                                        <div class="galleryItemLeftOptions">
                                            <div class="ctmFeaturedCheckBox">
                                                <label class="ctmFeaturedContainer">
                                                    <input type="checkbox" v-model="checkedItems" :value="gallery.id"/>
                                                    <span class="checkmark"></span>
                                                </label>
                                            </div>
                                        </div>
                                        <div class="galleryItemRightOptions">
                                            <button @click.prevent="openImagePreview(gallery)" class="zoomBtn">
                                                <el-tooltip class="box-item" effect="dark" content="Zoom" placement="top-start">
                                                    <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M12.9 14.32a8 8 0 1 1 1.41-1.41l5.35 5.33-1.42 1.42-5.33-5.34zM8 14A6 6 0 1 0 8 2a6 6 0 0 0 0 12zM7 7V5h2v2h2v2H9v2H7V9H5V7h2z" fill-rule="evenodd" fill="#1ec2c2"/>
                                                    </svg>
                                                </el-tooltip>
                                            </button>
                                            <button @click.prevent="handleDelete(gallery)">
                                                <el-tooltip class="box-item" effect="dark" content="Remove" placement="top-start">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15">
                                                        <path id="close" d="M24,10.313,22.687,9,16.5,15.187,10.313,9,9,10.313,15.187,16.5,9,22.687,10.313,24,16.5,17.813,22.687,24,24,22.687,17.813,16.5Z" transform="translate(-9 -9)" fill="#1ec2c2"/>
                                                    </svg>
                                                </el-tooltip>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- load more block starts here -->
                            <div class="bannerSliderItem gallery" v-else>
                                <vue-skeleton-loader
                                    type="rect"
                                    :width="210"
                                    :height="210"
                                    animation="fade"
                                />
                            </div>
                            <!-- load more block ends here -->

                        </div>
                    </div>
                    <!-- gallery items ends here -->

                    <!-- no gallery items starts here -->
                    <div class="noGalleryItems" v-if="galleryList.length == 0 && loadingItems == false">
                        <div class="noItemText">
                            <h3>No Media Available</h3>
                        </div>
                    </div>
                    <!-- no gallery items ends here -->

                </div>

                <!--bottom action bar starts here-->
                <div class="row product-bottom-bar gallery" v-if="checkedItems.length > 1">
                    <div class="col-md-6 model-footer-main1">
                        <div class="bottom-bar">
                            <a href="" class="model-footer-1 mr-5" @click.prevent="toggleIsCheckedAll()" v-if="checkedItems.length != galleryList.length">
                                <i class="fa fa-check-square-o" aria-hidden="true"></i>
                                <p>SELECT ALL</p>
                            </a>
                            <a href="" class="model-footer-1" @click.prevent="clearSelection()">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.026 14.027">
                                    <path id="cross-small" d="M18.969,6.023h0a1.079,1.079,0,0,0-1.525,0L12.5,10.971,7.549,6.023a1.079,1.079,0,0,0-1.525,0h0a1.079,1.079,0,0,0,0,1.525L10.971,12.5,6.023,17.443a1.079,1.079,0,0,0,0,1.525h0a1.079,1.079,0,0,0,1.525,0L12.5,14.021l4.947,4.947a1.079,1.079,0,0,0,1.525,0h0a1.079,1.079,0,0,0,0-1.525L14.021,12.5l4.947-4.947A1.079,1.079,0,0,0,18.969,6.023Z" transform="translate(-5.483 -5.483)" fill="#fd4d5d" stroke="#fd4d5d" stroke-width="0.3"/>
                                </svg>
                                <p>CLEAR SELECTION</p>
                            </a>
                        </div>
                        <div class="model-footer-2">{{ checkedItems.length }}</div>
                    </div>
                    <div class="col-md-6 model-footer-main1 product-row-reverse">
                        <div class="bottom-bar">
                            <a href="" class="model-footer-1 ml-4" @click.prevent="galleryItemsBulkDeleteHandle()">
                                <i class="fa fa-trash-o" aria-hidden="true"></i>
                                <p>DELETE</p>
                            </a>
                        </div>
                    </div>
                </div>
                <!--bottom action bar ends here-->

            </div>
        </div>
    </div>

    <!-- video preview modal starts here -->
    <div class="modalBg" :class="videoDialogVisible ? 'modal-active' : ''">
        <div class="modalLayers" @click.prevent="closeVideoPreviewHandle"></div>
        <div class="modalBody">
            <div class="oderSlideBox productSideBox" style="position: relative; top: 15px; right: 0; width: 100%;">
                <div class="productZoomImg">
                    <img src="/images/video_not_found.png" v-if="videoLoadError">
                    <video :src="videoDialogUrl" controls @error="handlePopupVideoError" v-else></video>
                </div>
            </div>
            <div class="modalCloseIcon">
                <button @click.prevent="closeVideoPreviewHandle">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.026 14.027">
                        <path id="cross-small" d="M18.969,6.023h0a1.079,1.079,0,0,0-1.525,0L12.5,10.971,7.549,6.023a1.079,1.079,0,0,0-1.525,0h0a1.079,1.079,0,0,0,0,1.525L10.971,12.5,6.023,17.443a1.079,1.079,0,0,0,0,1.525h0a1.079,1.079,0,0,0,1.525,0L12.5,14.021l4.947,4.947a1.079,1.079,0,0,0,1.525,0h0a1.079,1.079,0,0,0,0-1.525L14.021,12.5l4.947-4.947A1.079,1.079,0,0,0,18.969,6.023Z" transform="translate(-5.483 -5.483)" fill="#fd4d5d" stroke="#fd4d5d" stroke-width="0.3"/>
                    </svg>
                </button>
            </div>
        </div>
    </div>
    <!-- video preview modal ends here -->

    <!-- image preview modal starts here -->
    <div class="modalBg" :class=" imageDialogVisible ? 'modal-active' : ''">
        <div class="modalLayers" @click.prevent="closeImagePreviewHandle"></div>
        <div class="modalBody">
            <div class="oderSlideBox productSideBox" style="position: relative; top: 15px; right: 0; width: 100%;">
                <div class="productZoomImg">
                    <img :src="imageDialogUrl" alt="" @error="handleImageError">
                </div>
            </div>
            <div class="modalCloseIcon">
                <button @click.prevent="closeImagePreviewHandle">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.026 14.027">
                        <path id="cross-small" d="M18.969,6.023h0a1.079,1.079,0,0,0-1.525,0L12.5,10.971,7.549,6.023a1.079,1.079,0,0,0-1.525,0h0a1.079,1.079,0,0,0,0,1.525L10.971,12.5,6.023,17.443a1.079,1.079,0,0,0,0,1.525h0a1.079,1.079,0,0,0,1.525,0L12.5,14.021l4.947,4.947a1.079,1.079,0,0,0,1.525,0h0a1.079,1.079,0,0,0,0-1.525L14.021,12.5l4.947-4.947A1.079,1.079,0,0,0,18.969,6.023Z" transform="translate(-5.483 -5.483)" fill="#fd4d5d" stroke="#fd4d5d" stroke-width="0.3"/>
                    </svg>
                </button>
            </div>
        </div>
    </div>
    <!-- image preview modal ends here -->

  </div>
</template>

<script>
import {MessageBox, Message, Loading} from 'element-ui'
import subHeader from "@/components/subHeader.vue";
import { mapGetters } from 'vuex'
import tutorialsAction from "@/components/tutorialsAction.vue";
import _ from 'lodash';

export default {
    metaInfo() {
        return {
            title: 'Content Management | Gallery | YeetCommerce',
        };
    },
    data:()=>({
        base_image_url: process.env.VUE_APP_STORE_IMAGE_BASE_URL,
        
        galleryList: [],
        loadingItems: false,
        videoDialogVisible: false,
        videoDialogUrl: '',
        imageDialogVisible: false,
        imageDialogUrl: '',

        videoLoadError: true,
        loadingErrorVideos: [],

        //bulk delete
        checkedItems: [],
        deletingItems: [],
        deleteLoader: false,
    }),
    components:{
        subHeader,
        tutorialsAction,
    },
    computed:{
        ...mapGetters({
            gallery: 'gallery_module/gallery',
            galleryCurrentPage: 'gallery_module/currentPage',
            galleryLastPage: 'gallery_module/lastPage',
            pending: 'gallery_module/isPending',
            setting: 'settings_module/settings'
        }),
    },
    watch:{
        'gallery':{
            handler:function(val){

                this.galleryList = this.galleryList.filter((galleryItem) => {

                    if(galleryItem.file_type != 'loader'){

                        return galleryItem;

                    }

                });

                this.galleryList = _.cloneDeep(val)

                this.loadingItems = false;

            },deep:true
        }
    },
    methods:{

        //infinite scroll start
        handleScrollEvent(event){

            let maxScrollTop = document.documentElement.scrollHeight - document.documentElement.clientHeight;

            if(document.documentElement.scrollTop >= maxScrollTop - 100){
                
                if(this.pending == false && this.galleryCurrentPage != this.galleryLastPage){

                    let loadItem = {
                        file_type: 'loader',
                    }

                    for(let i = 0; i < 7; i++){

                        this.galleryList.push(loadItem);

                    }

                    this.$store.dispatch('gallery_module/fetchGalleryRequest',{ size: 28 ,page: this.galleryCurrentPage + 1, loadData:'more' });
                
                }

            }

        },
        //infinite scroll end

        openVideoPreview(galleryItem){

            this.videoDialogUrl = this.base_image_url + galleryItem.store_id + '/' + galleryItem.folder + '/' + galleryItem.file;
            this.videoDialogVisible = true;

            this.videoLoadError = false;

        },
        closeVideoPreviewHandle(){

            this.videoDialogVisible = false;
            // this.videoDialogUrl = '';

            this.videoLoadError = false;

        },
        openImagePreview(galleryItem){

            this.imageDialogUrl = this.base_image_url + galleryItem.store_id + '/' + galleryItem.folder + '/' + galleryItem.file;
            this.imageDialogVisible = true;

        },
        closeImagePreviewHandle(){ 

            this.imageDialogVisible = false;
            // this.imageDialogUrl = '';

        },
        async handleDelete(gallery){

            MessageBox.confirm(
                'Are you sure you want to delete? File will also be removed from the source.',
                'Warning',
                {
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'Cancel',
                    type: 'warning',
                }
            ).then(async ()=>{

                let loader = Loading.service({
                    text: "The file is being removed. Please wait!",
                    fullscreen: true,
                });

                this.deletingItems.push(gallery.id);
                this.deleteLoader = true;

                try{
                    let res = await this.$axios.delete('/gallery/remove/'+gallery.id);
                    if(res.data.status_code == "1284"){

                        this.$notify({
                            type: 'success',
                            title: 'Success',
                            message: 'The file has been successfully removed.',
                        });

                        this.$store.commit('gallery_module/delete_gallery_item',gallery);

                        if(res.data.module == 'category'){

                            this.$store.commit('productCategories_module/CLEAR_MODULE');

                        }else if(res.data.module == 'brand'){

                            this.$store.commit('brands_module/CLEAR_MODULE');

                        }else if(res.data.module == 'product'){

                            this.$store.commit('products_module/CLEAR_MODULE');

                            this.$store.commit('orders_module/CLEAR_MODULE');

                        }else if(res.data.module == 'product-bundle'){

                            this.$store.commit('product_bundles_module/CLEAR_MODULE');

                        }else if(res.data.module == 'customer'){

                            this.$store.commit('customers_module/CLEAR_MODULE');

                        }else if(res.data.module == 'admin'){

                            this.$store.state.user.image = null

                            this.$store.dispatch("settings_module/fetchStoreSetting");

                        }else if(res.data.module == 'banner'){

                            this.$store.commit('home_banner_module/CLEAR_MODULE');

                        }else if(res.data.module == 'page_details'){

                            this.$store.commit('page_details_module/CLEAR_MODULE');

                        }else if(res.data.module == 'blog'){

                            this.$store.commit('blogs_module/CLEAR_MODULE');

                        }else if(res.data.module == 'blog_category'){

                            this.$store.commit('blogCategories_module/CLEAR_MODULE');

                        }else if(res.data.module == 'bills'){

                            this.$store.commit('bills_module/CLEAR_MODULE');
                            this.$store.commit('vendor_module/CLEAR_MODULE');

                        }else if(res.data.module == 'expenses'){

                            this.$store.commit('expenses_module/CLEAR_MODULE');
                            this.$store.commit('vendor_module/CLEAR_MODULE');

                        }else if(res.data.module == 'vendor'){

                            this.$store.commit('vendor_module/CLEAR_MODULE');

                        }else if(res.data.module == 'settings'){

                            this.$store.dispatch('settings_module/fetchStoreSetting');

                        }else if(res.data.module == 'communication'){

                            this.$store.commit('communication_module/CLEAR_MODULE');

                        }else if(res.data.module == 'crm-contact'){

                            this.$store.commit('contacts_module/CLEAR_MODULE');

                        }else if(res.data.module == 'email-template'){

                            this.$store.commit('email_templates_module/CLEAR_MODULE');

                        }
                
                        this.$store.commit('settings_module/update_remaining_space',res.data.remaining_space.usage_space);

                        //removing deleted item from checked items list
                        this.checkedItems = this.checkedItems.filter((item) => {

                            if(item != gallery.id){
                                
                                return item;
                                
                            }

                        });

                    }
                }catch(error){

                    if(error.response){

                        if(error.response.data.error.id){

                            this.$message({
                                type: 'error',
                                showClose: true,
                                message: error.response.data.error.id[0],
                            });

                        }else{

                            this.$message({
                                type: 'error',
                                showClose: true,
                                message: error.response.data.message,
                            });

                        }

                    }else{

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.message,
                        });

                    }

                }finally{

                    this.deletingItems = [];
                    this.deleteLoader = false;

                    loader.close();

                }

            }).catch(()=>{})

        },

        //bulk delete
        clearSelection(){

            MessageBox.confirm(
                'Do you really want to uncheck the selected records?',
                'Confirm',
                {
                    distinguishCancelAndClose: true,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                }
            ).then(async () => {

                this.checkedItems = [];

            }).catch(() => {})

        },
        toggleIsCheckedAll(){

            if(this.checkedItems.length == this.galleryList.length){

                this.checkedItems = [];

            }else if(this.checkedItems.length < this.galleryList.length){

                this.checkedItems = []

                this.galleryList.forEach((galleryItem) =>{

                    this.checkedItems.push(galleryItem.id);

                });

            }

        },
        isCheckedItem(id){

            let find = this.checkedItems.find(itemId => itemId == id);

            if(find){

                return true;

            }else{

                return false;

            }

        },
        isDeletingItem(id){

            let find = this.deletingItems.find(itemId => itemId == id);

            if(find){

                return true;

            }else{

                return false;
                
            }

        },
        galleryItemsBulkDeleteHandle(){

            MessageBox.confirm(
                'Are you sure you want to delete? Images will also be removed from the source.',
                'Warning',
                {
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'Cancel',
                    type: 'warning',
                }
            ).then(async ()=>{

                let loader = Loading.service({
                    text: "The files are being removed. Please wait!",
                    fullscreen: true,
                });

                let formData = new FormData();

                formData.append('resource_type', 'gallery');
                formData.append('resource_ids',JSON.stringify(this.checkedItems));
                formData.append("action", 'delete');

                this.deletingItems = _.cloneDeep(this.checkedItems);
                this.deleteLoader = true;

                try{
                    let res = await this.$axios.post('/bulk-action',formData)
                    if(res.data.status_code == "2031"){

                        this.$notify({
                            type: 'success',
                            title: 'Success',
                            message: 'Files have been successfully removed.',
                        });

                        this.$store.dispatch('gallery_module/fetchGalleryRequest',{ size: 28 ,page: 1, loadData:'new' });

                        this.$store.commit('settings_module/update_remaining_space',res.data.remaining_space.usage_space);

                        let modules = _.cloneDeep(res.data.modules);

                        modules.forEach(listedModule => {
                            
                            if(listedModule == 'category'){
                                
                                this.$store.commit('productCategories_module/CLEAR_MODULE');

                            }else if(listedModule == 'brand'){

                                this.$store.commit('brands_module/CLEAR_MODULE');

                            }else if(listedModule == 'product'){

                                this.$store.commit('products_module/CLEAR_MODULE');

                            }else if(res.data.module == 'product-bundle'){

                                this.$store.commit('product_bundles_module/CLEAR_MODULE');

                            }else if(listedModule == 'customer'){

                                this.$store.commit('customers_module/CLEAR_MODULE');

                            }else if(listedModule == 'admin'){

                                this.$store.state.user.image = null;

                                this.$store.dispatch("settings_module/fetchStoreSetting");

                            }else if(listedModule == 'banner'){

                                this.$store.commit('home_banner_module/CLEAR_MODULE');

                            }else if(listedModule == 'page_details'){

                                this.$store.commit('page_details_module/CLEAR_MODULE');

                            }else if(listedModule == 'blog'){

                                this.$store.commit('blogs_module/CLEAR_MODULE');

                            }else if(listedModule == 'blog_category'){

                                this.$store.commit('blogCategories_module/CLEAR_MODULE');

                            }else if(listedModule == 'bills'){

                                this.$store.commit('bills_module/CLEAR_MODULE');
                                this.$store.commit('vendor_module/CLEAR_MODULE');

                            }else if(listedModule == 'expenses'){

                                this.$store.commit('expenses_module/CLEAR_MODULE');
                                this.$store.commit('vendor_module/CLEAR_MODULE');

                            }else if(listedModule == 'vendor'){

                                this.$store.commit('vendor_module/CLEAR_MODULE');

                            }else if(listedModule == 'settings'){

                                this.$store.dispatch('settings_module/fetchStoreSetting');

                            }else if(listedModule == 'communication'){

                                this.$store.commit('communication_module/CLEAR_MODULE');

                            }else if(listedModule == 'crm-contact'){

                                this.$store.commit('contacts_module/CLEAR_MODULE');

                            }else if(listedModule == 'email-template'){

                                this.$store.commit('email_templates_module/CLEAR_MODULE');

                            }

                        });

                        this.checkedItems = [];

                    }
                }catch(error){

                    if(error.response.data.message){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.message,
                        });

                    }else{

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: "One of the files may have been deleted or isn't available.",
                        });

                    }

                }finally{

                    this.deletingItems = [];
                    this.deleteLoader = false;

                    loader.close();

                }

            }).catch(()=>{})

        },

        //image loading error handling
        handleImageError(e){

            e.target.src = '/images/image_not_found.png'

        },
        //video loading error handling
        handlePopupVideoError(e){

            this.videoLoadError = true;

        },
        handleMainVideoError(e,listedGalleryItem){

            this.loadingErrorVideos.push(listedGalleryItem.id);

        },
        isErrorVideo(listedGalleryItem){

            let find = this.loadingErrorVideos.find(x => x == listedGalleryItem.id);

            if(find){

                return true;

            }else{

                return false;

            }

        },
    },
    mounted(){

        window.addEventListener('scroll', this.handleScrollEvent);

    },
    async beforeMount(){

        if(!this.gallery || this.gallery.length == 0){

            this.loadingItems = true;

            this.$store.dispatch('gallery_module/fetchGalleryRequest',{ size: 28 ,page: 1, loadData:'new' });

        }else{

            this.loadingItems = false;

            this.galleryList = this.gallery;

        }
        
    },
    beforeRouteLeave(to, from, next){
        
        window.removeEventListener('scroll',this.handleScrollEvent);

        next();

    },
}
</script>

<style>

</style>